import React from "react";

function ShippingPolicy() {
  return (
    <section
      id="hero"
      className="hero d-flex align-items-center"
      style={{ background: "whitesmoke", marginTop: "-50px" }}
    >
      <div className="container mt-5">
        <h2>Refund and Cancellation policy</h2>
        <p>
          {" "}
          This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service that
          you have purchased through the Platform. Under this policy:
        </p>
        <p>
          Cancellations will only be considered if the request is made 3 days of placing the order. However,
          cancellation requests may not be entertained if the orders have been communicated to such sellers /
          merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out
          for delivery. In such an event, you may choose to reject the product at the doorstep
        </p>
        <p>
          NatureMonk does not accept cancellation requests for perishable items like flowers, eatables, etc. However,
          the refund / replacement can be made if the user establishes that the quality of the product delivered is not
          good
        </p>
        <p>
          In case of receipt of damaged or defective items, please report to our customer service team. The request
          would be entertained once the seller/ merchant listed on the Platform, has checked and determined the same at
          its own end. This should be reported within 3 days of receipt of products. In case you feel that the product
          received is not as shown on the site or as per your expectations, you must bring it to the notice of our
          customer service within 3 days of receiving the product. The customer service team after looking into your
          complaint will take an appropriate decision.
        </p>
        <p>
          In case of complaints regarding the products that come with a warranty from the manufacturers, please refer
          the issue to them.
        </p>
        <p>In case of any refunds approved by NatureMonk, it will take 7 days for the refund to be processed to you</p>
        <div className="my-3"></div>
       
        
        <div className="my-3"></div>
        <h2>Shipping Policy</h2>
        <p>
          The orders for the user are shipped through registered domestic courier companies and/or speed post only.
          Orders are shipped within 3 days from the date of the order and/or payment or as per the delivery date agreed
          at the time of order confirmation and delivering of the shipment, subject to courier company / post office
          norms. Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority.
          Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of
          our services will be confirmed on your email ID as specified at the time of registration. If there are any
          shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable.
        </p>
      </div>
    </section>
  );
}

export default ShippingPolicy;
