import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getProductDetails } from "../services/product.service";
const ProductCheckOutPage = () => {
    const params= useParams()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    cardName: "",
    cardNumber: "",
    expiration: "",
    cvv: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Order placed successfully!");
  };
  const [productDetails, setProductDetails] = useState();
  const [loader, setLoader] = useState(false);
  const handleGetProductDetails = async (id) => {
    setLoader(true);
    try {
      let response = await getProductDetails(id);
      setProductDetails(response?.data?.data);
      setLoader(false);
      console.log(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    handleGetProductDetails(params?.id);
  }, [params?.id]);
  return (
    <div className="container py-5 mt-5">
      <h3 className="mb-4 fontMono" >Checkout</h3>
      <div className="row">
        {/* Billing Details */}
        <div className="col-md-6">
          <h5>Billing Address</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="row">
                <div className="col-6">
                <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
                </div>
                <div className="col-6">
                <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Contact Number
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
                </div>
            </div>
            

            
            
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-2">
              <div className="mb-3">
              <label htmlFor="address" className="form-label">
                House No.
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
              </div>
              <div className="col-md-2 mb-3">
                <label htmlFor="zip" className="form-label">
                  Zip
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <div className="">
            <img src={productDetails?.productHeroImg} className="img-fluid" style={{height:"200px"}}/>
            <h5 className="my-4">Order Summary</h5>
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between lh-sm">
                <div>
                  <h6 className="my-0">{productDetails?.title}</h6>
                  <small className="text-muted">{productDetails?.quantity}</small>
                </div>
                <span className="text-muted">&#x20B9; {productDetails?.discountedPrice}</span>
              </li>
              
            </ul>
            <div className="d-flex justify-content-between align-items-center ">
            <button type="submit" className="btn btn-success  btn-block" onClick={handleSubmit}>
              Place Order
            </button>
                <Link to="/shipping-policy"><u>Shipping Policy</u></Link>
            </div>
          </div>
        </div>
      </div>
      {/* Order Summary */}
    </div>
  );
};

export default ProductCheckOutPage;
